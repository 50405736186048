import styled, { keyframes } from "styled-components";
import { Spin } from 'antd';


// return the user data from the session storage
  export const getUser = () => {
    const userStr = sessionStorage.getItem('user');
    if (userStr) 
      return JSON.parse(userStr);
    else 
      return null;
  }

  export const getLocalStorageInfo = (item) => {
    const userStr = localStorage.getItem(item);
    if (userStr) 
      return JSON.parse(userStr);
    else 
      return null;
  }

  
  // return the token from the session storage
  export const getToken = () => {
    return sessionStorage.getItem('call_token') || null;
    
  }
  
  // return the token from the session storage
  export const getLocalToken = () => {
    return localStorage.getItem('call_token') || null; 
  }

  export const setLocalStorageInfo = (itemName, setItem) => {
    return localStorage.setItem(itemName, setItem) || null; 
  }
  
  // remove the token and user from the session storage
  export const removeUserSession = () => {
    sessionStorage.removeItem('call_token');
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('curUser');
  }
  
  // set the token and user from the session storage
  export const setUserSession = (token, user) => {
    sessionStorage.setItem('call_token', token);
    sessionStorage.setItem('user', JSON.stringify(user));
  }

  export const deleteCookie = (name) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
   };


  export const Loading = () => {
    return (
      <div style={{ textAlign: 'center', padding: '20px' }}>
      <Spin size="large" /> {/* Show loading spinner */}
      <p>Loading...</p> {/* Optional: Show a loading message */}
      </div>
    );
  }

  const BounceAnimation = keyframes`
    0% { margin-bottom: 0; }
    50% { margin-bottom: 15px }
    100% { margin-bottom: 0 }
  `;
  const DotWrapper = styled.div`
    display: flex;
    align-items: flex-end;
  `;
  const Dot = styled.div`
    background-color: black;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    /* Animation */
    animation: ${BounceAnimation} 0.5s linear infinite;
    animation-delay: ${props => props.delay};
  `;

  export const LoadingSpinner = () => {
    return (
      <div className="spinner-container">
        <div className="loading-spinner">
        </div>
      </div>
    );
  }

  export const getFullDate = (date) => {
    // const dateAndTime = date.split('T');
    // return dateAndTime[0].split('-').reverse().join('-');
    const dateAndTime = date.split('T');
    return dateAndTime[0]; // Y-m-d format
  };
  
  export const getFullDateTime = (date) => {
    // Create a new Date object from the input date string
    const dateObj = new Date(date);

    // Format the date part to "DD-MM-YYYY"
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = dateObj.getFullYear();

    // Format the time part to "HH:MM:SS"
    const hours = String(dateObj.getHours()).padStart(2, '0');
    const minutes = String(dateObj.getMinutes()).padStart(2, '0');
    const seconds = String(dateObj.getSeconds()).padStart(2, '0');

    // Combine the formatted date and time parts
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;

  };

  export const getShortDate = (date) => {
    // Create a new Date object from the input date string
    const dateObj = new Date(date);

    // Format the date part to "DD-MM-YYYY"
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = dateObj.getFullYear();

    // Combine the formatted date and time parts
    return `${year}-${month}-${day}`;

  };


  export const getLabelByValue = (reproductionItems, value) => {
    const item = reproductionItems.find(item => item.value === value);
    return item ? item.label : null;
  }
  
  export const secondsToHHMMSS = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    if(seconds) {
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    } else {
      return null;
    }
  };

  export const getCurYear = () => {
    return new Date().getFullYear();
  }

  export const getCurMonth = () => {
    return new Date().getMonth()+1;
  }

  export const getCurDate = () => {
    return new Date().getDate();
  }

  export const sortArrayObject = (listData, a, b) => {
    return listData.sort(function(a, b){
      var keyA = a.total_call,
          keyB = b.total_call;
      // Compare the 2 key
      if(keyA > keyB) return -1;
      if(keyA < keyB) return 1;
      return 0;
    });
  }

  export const formatDate = (isoString) => {
    const date = new Date(isoString);
  
    // Trừ 7 múi giờ
    date.setHours(date.getHours() - 7);
  
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  export const getSystemHourFromDate = (dateString) => {
    // Tạo đối tượng Date từ chuỗi ngày tháng (giả định chuỗi đã ở dạng 'YYYY-MM-DD HH:mm:ss')
    const date = new Date(dateString.replace(' ', 'T'));
  
    // Lấy giờ hiện tại theo múi giờ hệ thống
    const systemHour = date.getHours();
  
    return systemHour;
  };

  export function formatDateToYYYYMMDDHHMMSS(dateTime) {
    // Create a Date object
    const date = new Date(dateTime);
  
    // Convert to the desired timezone using toLocaleString
    const options = {
      timeZone: "Asia/Tokyo",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false, // Use 24-hour time
    };
  
    // Get the formatted date and time in 'Asia/Tokyo' timezone
    const formattedString = date.toLocaleString("en-US", options);
  
    // Split the formatted string into date and time components
    const [datePart, timePart] = formattedString.split(", ");
  
    // Reformat the date part to 'yyyy-mm-dd'
    const [month, day, year] = datePart.split("/");
    const formattedDate = `${year}-${month}-${day}`;
  
    // Reformat the time part to 'H:i:s'
    const [hour, minute, second] = timePart.split(":");
    const formattedTime = `${hour}:${minute}:${second}`;
  
    // Combine the date and time parts
    return `${formattedDate} ${formattedTime}`;
  }