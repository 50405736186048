import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Table, Tag, Space, Layout, message, Spin, Tooltip } from "antd";
import {
  PauseCircleOutlined,
  PlayCircleOutlined,
  CloudDownloadOutlined,
  ReloadOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

import HeaderLayout from "../../layout/Header";
import NavBarLayout from "../../layout/NavBar";
import FooterLayout from "../../layout/Footer";
import { formatDateToYYYYMMDDHHMMSS, getShortDate } from "../../utils/Common";
import {
  downloadCallData,
  getCallData,
} from "../../services/instanceCallService";

const { Content } = Layout;

const CallLogs = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [logData, setLogData] = useState([]);
  const [isWaiting, setIsWaiting] = useState(false);
  const [audio, setAudio] = useState(null);
  const [isPlaying, setIsPlaying] = useState("");
  const [isDownloading, setIsDownloading] = useState("");
  const [currentAudioId, setCurrentAudioId] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setIsWaiting(true);
      const result = await getCallData("/call-log/list");
      setLogData(result);
    } catch (error) {
      messageApi.success("Failed to delete project");
    } finally {
      setIsWaiting(false);
    }
  };

  const replayAudio = useCallback(() => {
    if (audio) {
      audio.currentTime = 0; // Reset the audio to the beginning
      audio.play();
      setIsPlaying("true");
    }
  }, [audio]);

  const togglePlayPause = useCallback(
    async (logId) => {
      setIsWaiting(true);
      if (currentAudioId && currentAudioId === logId) {
        if (audio) {
          if (audio.paused) {
            audio.play();
            setIsPlaying(logId);
          } else {
            audio.pause();
            setIsPlaying("");
          }
        }
        setIsWaiting(false);
        return;
      }
      if (audio) window.URL.revokeObjectURL(audio.src);

      try {
        const audioFile = await fetchAudio(logId);

        if (audioFile) {
          const url = window.URL.createObjectURL(audioFile);
          const newAudio = new Audio(url);
          newAudio.oncanplaythrough = () => {
            setAudio(newAudio);
            setIsPlaying(logId);
            setCurrentAudioId(logId);
            newAudio.play();
          };
          newAudio.onerror = (error) => {
            console.error("Error playing audio", error);
            setIsPlaying(false);
          };
          newAudio.onended = () => {
            setIsPlaying(false);
            window.URL.revokeObjectURL(url);
          };
        }
      } catch (error) {
        console.error("Error downloading or playing audio", error);
        setIsWaiting(false);
      } finally {
        setIsDownloading("");
        setIsWaiting(false);
      }
    },
    [audio, currentAudioId]
  );

  const fetchAudio = useCallback(async (logId) => {
    try {
      const audio = await downloadCallData(`/call-log/download/${logId}`);
      return audio;
    } catch (error) {
      console.error("Error fetching audio:", error);
      return null;
    }
  }, []);

  const downloadRecording = useCallback(
    async (logId) => {
      setIsDownloading(logId);
      const blob = await fetchAudio(logId);
      if (blob) {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${logId}.mp3`; // set the file name
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url); // free memory
      }
      setIsDownloading("");
    },
    [fetchAudio]
  );

  const columns = useMemo(
    () => [
      { title: "姓名", dataIndex: "name", key: "name", width: 170 },
      { title: "誕生日", dataIndex: "birthday", key: "birthday", width: 120 },
      {
        title: "応対者",
        dataIndex: "caller_name",
        key: "caller_name",
        width: 100,
        render: (caller_name) => <>{caller_name}</>,
      },
      { title: "フリガナ", key: "furigana", dataIndex: "furigana", width: 170 },
      { title: "通話日", dataIndex: "call_date", key: "call_date", width: 120 },
      {
        title: "再生",
        dataIndex: "url_recording",
        key: "url_recording",
        render: (url_recording, record) =>
          record.play_back === 1 ? (
            <Space>
              <Tooltip
                title="Play/Pause Recording"
                color="cyan"
                key={`play_pause_${record.key}`}
              >
                <a onClick={() => togglePlayPause(record.url_recording)}>
                  {isPlaying === record.url_recording ? (
                    <PauseCircleOutlined
                      style={{ color: "hotpink", fontSize: "20px" }}
                    />
                  ) : (
                    <PlayCircleOutlined
                      style={{ color: "#52c41a", fontSize: "20px" }}
                    />
                  )}
                </a>
              </Tooltip>
              <Tooltip
                title="Replay Recording"
                color="cyan"
                key={`replay_${record.key}`}
              >
                <a onClick={() => replayAudio()}>
                  <ReloadOutlined
                    style={{
                      color: "#55f",
                      fontSize: "20px",
                      marginLeft: "10px",
                    }}
                  />
                </a>
              </Tooltip>
              <Tooltip
                title="Download Recording"
                color="cyan"
                key={`down_${record.key}`}
              >
                {isDownloading === record.url_recording ? (
                  <LoadingOutlined
                    style={{
                      color: "blue",
                      fontSize: "20px",
                      marginLeft: "10px",
                    }}
                  />
                ) : (
                  <a onClick={() => downloadRecording(record.url_recording)}>
                    <CloudDownloadOutlined
                      style={{
                        color: "hotpink",
                        fontSize: "20px",
                        marginLeft: "10px",
                      }}
                    />
                  </a>
                )}
              </Tooltip>
            </Space>
          ) : null,
        width: 120,
      },
      {
        title: "結果の呼び出し",
        dataIndex: "result_call",
        key: "result_call",
        width: 130,
      },
      {
        title: "ステータス",
        dataIndex: "status",
        key: "status",
        render: (status) => (
          <Tag color={status ? "volcano" : "geekblue"}>
            {status ? "Active" : "Inactive"}
          </Tag>
        ),
        width: 130,
      },
      {
        title: "性別",
        dataIndex: "gender",
        key: "gender",
        width: 120,
        render: (gender) => <>{gender}</>,
      },
      { title: "電話番号", dataIndex: "phone", key: "phone", width: 120 },
      { title: "住所", dataIndex: "address", key: "address", width: 120 },
      {
        title: "郵便番号",
        dataIndex: "postal_code",
        key: "postal_code",
        width: 120,
      },
      {
        title: "大学名",
        dataIndex: "university_name",
        key: "university_name",
        width: 120,
      },
      {
        title: "学校種類",
        dataIndex: "school_type",
        key: "school_type",
        width: 120,
      },
      {
        title: "学部学科",
        dataIndex: "department",
        key: "department",
        width: 120,
      },
      {
        title: "文理",
        dataIndex: "educational_stream",
        key: "educational_stream",
        width: 130,
      },
      {
        title: "卒業年",
        dataIndex: "graduation_year",
        key: "graduation_year",
        width: 120,
      },
      {
        title: "就職希望年度",
        dataIndex: "work_year",
        key: "work_year",
        width: 120,
      },
      {
        title: "志望業界",
        dataIndex: "desired_industry",
        key: "desired_industry",
        width: 120,
      },
      {
        title: "最終架電日",
        dataIndex: "last_call",
        key: "last_call",
        width: 145,
      },
    ],
    [isPlaying, isDownloading, togglePlayPause, replayAudio, downloadRecording]
  );

  const logsData = useMemo(() => {
    if (logData.data && Object.keys(logData.data).length > 0) {
      let logKey = 0;
      return logData.data.map((item) => ({
        key: logKey++,
        candidate: item.candidate_id,
        name: item.candidate_name,
        furigana: item.furigana,
        email: item.email,
        phone: item.candidate_phone,
        address: item.address,
        birthday: item.birthday ? getShortDate(item.birthday) : "",
        gender: item.gender,
        postal_code: item.postal_code,
        callee_number: item.callee_number,
        school_type: item.school_type,
        university_name: item.university_name,
        educational_stream: item.educational_stream,
        department: item.department,
        graduation_year: item.graduation_year,
        work_year: item.work_year,
        desired_industry: item.desired_industry,
        total_call: item.total_call,
        last_call: formatDateToYYYYMMDDHHMMSS(item.last_call),
        result_call: item.result,
        author_id: item.author_id,
        receipt_id: item.receipt_id,
        status: item.status,
        call_date: formatDateToYYYYMMDDHHMMSS(item.date_time),
        play_back: item.play_back,
        caller_name: item.caller_name,
        url_recording: item?.call_record_id ?? "",
      }));
    }
    return [];
  }, [logData]);

  return (
    <Layout>
      <HeaderLayout />
      {contextHolder}
      <Layout>
        <NavBarLayout />
        <Content style={{ padding: "10px" }}>
          <div style={{ minHeight: 780 }}>
            {isLoading ? (
              <div style={{ textAlign: "center", padding: "20px" }}>
                <Spin size="large" /> {/* Show loading spinner */}
                <p>Loading...</p> {/* Optional: Show a loading message */}
              </div>
            ) : (
              <>
                <h1>コールログ </h1>
                <Table
                  columns={columns}
                  dataSource={logsData}
                  rowKey="key"
                  pagination={false}
                  scroll={{ y: 690, x: 580 }}
                  loading={isWaiting}
                />
              </>
            )}
          </div>
          <FooterLayout />
        </Content>
      </Layout>
    </Layout>
  );
};

export default CallLogs;
