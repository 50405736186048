import global_const from "../constants/global_const";
import axios from "axios";
import { getCookies } from "../utils/getCookies";

const instanceManagementService = axios.create({
  baseURL: global_const.MANAGEMENT_URL,
  headers: {
    "Content-type": "application/json",
  },
});

instanceManagementService.interceptors.request.use(
  (config) => {
    const curToken = getCookies();
    config.headers.Authorization = `Bearer ${curToken}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const getManagementData = async (endpoint) => {
  try {
    const response = await instanceManagementService.get(endpoint);
    return response.data;
  } catch (error) {
    console.error("GET request error:", error);
    throw error;
  }
};

export const postManagementData = async (endpoint, data) => {
  try {
    const response = await instanceManagementService.post(endpoint, data);
    return response.data;
  } catch (error) {
    console.error("POST request error:", error);
    throw error;
  }
};

export const putManagementData = async (endpoint, data) => {
  try {
    const response = await instanceManagementService.put(endpoint, data);
    return response.data;
  } catch (error) {
    console.error("PUT request error:", error);
    throw error;
  }
};

export const deleteManagementData = async (endpoint) => {
  try {
    const response = await instanceManagementService.delete(endpoint);
    return response.data;
  } catch (error) {
    console.error("DELETE request error:", error);
    throw error;
  }
};
