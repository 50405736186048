import global_const from "../constants/global_const";
import axios from "axios";
import { getCookies } from "../utils/getCookies";

const instanceCallService = axios.create({
  baseURL: global_const.REPORT_URL,
  headers: {
    "Content-type": "application/json",
  },
});

instanceCallService.interceptors.request.use(
  (config) => {
    const curToken = getCookies();
    config.headers.Authorization = `Bearer ${curToken}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const getReport = async (endpoint) => {
  try {
    const response = await instanceCallService.get(endpoint);
    return response.data;
  } catch (error) {
    console.error("GET request error:", error);
    throw error;
  }
};

export const postReport = async (endpoint, data) => {
  try {
    const response = await instanceCallService.post(endpoint, data, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("POST request error:", error);
    throw error;
  }
};

export const putReport = async (endpoint, data) => {
  try {
    const response = await instanceCallService.put(endpoint, data, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("PUT request error:", error);
    throw error;
  }
};

export const deleteReport = async (endpoint) => {
  try {
    const response = await instanceCallService.delete(endpoint);
    return response.data;
  } catch (error) {
    console.error("DELETE request error:", error);
    throw error;
  }
};
