import React, { useContext, useMemo } from 'react';
import { Descriptions } from 'antd';
import { UserContext } from './UserContext';
import { getLabelByValue, } from '../../utils/Common';


const statusList = [
    { label: 'Unknow', value: 'NULL' },
    { label: '不在', value: '0' },
    { label: '興味なし', value: '1' },
    { label: '即断り', value: '3' },
    { label: '着信拒否', value: '4' },
    { label: '再アクション', value: '5' },
    { label: '面談アサイン', value: '6' },
    { label: 'イベントアサイン', value: '7' },
    { label: '電話番号不備', value: '8' },
    { label: '接触前', value: '9' },
    { label: '追客中', value: '10' },
    { label: 'サービス停止', value: '11' },
    { label: 'サービス停止（メールOK）', value: '12' },
    { label: '前日確認OK', value: '13' },
    { label: 'キャンセル', value: '14' },
    { label: '日程変更', value: '15' },

];

const keysToShow = [
    'ID',
    'Name',
    'furigana',
    'email',
    'phone',
    'address',
    'birthday',
    'gender',
    'postal_code',
    'university_name',
    'educational_stream',
    'department',
    'graduation_year',
    'desired_industry'
];


export default function PhoneDetail() {
    const { userInfo } = useContext(UserContext);

    const userItems = userInfo ? Object.entries(userInfo)
    .filter(([key]) => keysToShow.includes(key))  // Filter based on the keysToShow
    .map(([key, value]) => (
        <Descriptions.Item key={key.replace('_', ' ')} label={<b style={{color: 'hotpink'}}>{
            key === 'ID' ? 'NO.' :
            key === 'Name' ? '名前' : 
            key === 'furigana' ? 'フリガナ' : 
            key === 'email' ? 'メールアドレス' : 
            key === 'phone' ? 'Tel' : 
            key === 'address' ? '住所' : 
            key === 'birthday' ? '生年月日' : 
            key === 'gender' ? '性別' : 
            key === 'postal_code' ? '郵便番号' : 
            key === 'university_name' ? '学校名' : 
            key === 'educational_stream' ? '文理' : 
            key === 'department' ? '学部' : 
            key === 'graduation_year' ? '卒業年度' : 
            key === 'desired_industry' ? '志望業界' : ''

            }</b>}>
            <label style={{color: '#55f'}}><b>{
            key === 'status' ? getLabelByValue(statusList, value) : value
            }</b></label>
        </Descriptions.Item>
    )) : [];

    return (
        <div style={{ padding: '10px', height: '420px', backgroundColor: '#fff' }}>
            <Descriptions
                title="ユーザー情報"
                layout="vertical"
                bordered
                style={{ overflow: 'auto', height: 350 }}
            >
                {userItems}
            </Descriptions>
        </div>
    );
}
